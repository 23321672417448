
import { defineComponent } from 'vue';
import globals from '../../helpers/globals';
import PrimaryButton from '../../components/PrimaryButton.vue';
import StepData from '../../types/StepData';
import SegmentDataHelper from '../../helpers/SegmentDataHelper';
import UndefinedResourceError from '../../types/exceptions/UndefinedResourceError';

export default defineComponent({
  name: 'IsCustomerSelection',
  mixins: [
    globals,
  ],
  components: {
    PrimaryButton,
  },
  data() {
    return {
      list: [
        {
          pid: 'yes',
          name: 'Oui, au moins un compte domicilié',
        }, {
          pid: 'no',
          name: 'Non, pas encore',
        },
      ],
    };
  },
  created() {
    const vm = this;

    /* Try to set profile if authenticated */
    this.setProfile(this.$store);

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      vm.$store.dispatch('setSelectedSegment', SegmentDataHelper.getObjectFromStore(vm.$store, vm.$route.params.segment as string));

      if (Object.keys(vm.$store.state.selected.segment).length === 0) {
        throw new UndefinedResourceError();
      }

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();

    localStorage.removeItem('isCustomer');
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);
      this.$store.dispatch('toggleTopBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      SegmentDataHelper.prepareComponentData(
        this.$store,
        callback,
      );
    },
    setIsCustomer(target: any) {
      /* FLAG STEP AS COMPLETED BEFORE MOVING TO NEXT SCREEN */
      this.setStepAsCompleted(this, true);

      this.$store.dispatch('setDataIntoStep', { step: this.$options.name, data: { isCustomer: target.pid } } as StepData);

      // TODO: Needs  to find a better way
      localStorage.isCustomer = target.pid;

      this.goToNextScreen(this, {
        segment: this.$store.state.selected.segment.pid,
      });
    },
  },
});
